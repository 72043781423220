<template>
  <div>
    <div class="home" :style="{ width: '100%', height: windowHeight }"></div>
    <div class="page-bottom-copyright">
      <a target="_blank" href="https://beian.miit.gov.cn/"
        >©2017 沪ICP备17040400号-1
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
    return {
      windowWidth: "",
      windowHeight: "",
    };
  },
  components: {
    // HelloWorld,
  },
  mounted() {
    this.windowHeight = window.screen.height + "px";
  },
};
</script>

<style>
.home {
  background-image: url("../assets/home-background.jpg");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.page-bottom-copyright {
  font-size: 12px;
  margin: 16px 0;
  a {
    color: #7a869a !important;
  }
}
</style>
